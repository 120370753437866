import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4>{`Distinctions`}</h4>
    <p><strong parentName="p">{`Headstrong Brawler`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Faro has trained for the majority of his life in many different forms of hand-to-hand combat, and is currently one of `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{`'s top `}<a parentName="p" {...{
        "href": "/Cato",
        "title": "Cato"
      }}>{`Cato`}</a>{` fighters.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`Keep Swinging`}</em>{`: When the `}<strong parentName="li">{`Injured`}</strong>{` stress is `}<inlineCode parentName="li">{`jsx: <D6/>`}</inlineCode>{`  or higher, step up `}<strong parentName="li">{`Injured`}</strong>{` and add it to your dice pool instead of your opponents.`}</li>
    </ul>
    <p><strong parentName="p">{`Everything Has a Price`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
For Faro, nothing has come easy. Everything he has in life has required sacrifices and determination.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
    </ul>
    <p><strong parentName="p">{`Actions Speak Louder Than Words`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Faro is a man of few words, and tends to keep his cards close to his chest.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
    </ul>
    <h4>{`Attributes`}</h4>
    <p><em parentName="p">{`Strength`}</em>{` `}<inlineCode parentName="p">{`jsx:<D10/>`}</inlineCode>{`
`}<em parentName="p">{`Dexterity`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
`}<em parentName="p">{`Intellect`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
`}<em parentName="p">{`Will`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
`}<em parentName="p">{`Awareness`}</em>{` `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
`}<em parentName="p">{`Conviction`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode></p>
    <h4>{`Skills`}</h4>
    <p>{`Craft `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
Fight `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Fix `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
Focus `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Influence `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
Labor `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
Move `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
Know `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode></p>
    <ul>
      <li parentName="ul">{`Hand-to-Hand Combat `}<inlineCode parentName="li">{`jsx:<D6/>`}</inlineCode></li>
      <li parentName="ul">{`Mettalurgy `}<inlineCode parentName="li">{`jsx:<D6/>`}</inlineCode>{`
Notice `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode>{`
Operate `}<inlineCode parentName="li">{`jsx:<D6/>`}</inlineCode>{`
Perform`}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode>{`
Shoot `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode>{`
Sneak `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode>{`
Survive `}<inlineCode parentName="li">{`jsx:<D6/>`}</inlineCode>{`
Treat `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode>{`
Trick `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
    </ul>
    <h4>{`Luminous Magic`}</h4>
    <p><strong parentName="p">{`Convictions of Augery`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Faro has chosen to focus his studies as an Acronist on `}<em parentName="p">{`Augery`}</em>{`. He excels at manifesting Convictions related to enhancing his physical abilities---speed, strength, etc.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Fixed Truth`}</em>{`: When manifesting a conviction related to Augery, spend a PP to create a `}<inlineCode parentName="li">{`jsx:<D8/>`}</inlineCode>{` asset out of the manifestation.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      